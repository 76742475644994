import React from "react";

import { ReactNode, RefObject } from "react";
import { ErrorHandler } from "src/abstracts/handleError";
import { Skeleton } from "src/shared-components/skeleton";
import { GenericNullable } from "src/types/GenericNullable";
import { PrivacyPolicyScreenHeader } from "./sections/header";
import { PrivacyPolicyScreenFooter } from "./sections/footer";
import { HeaderComponent } from "src/shared-components/header";
import { FooterComponent } from "src/shared-components/footer";
import { PrivacyPolicyScreenContent } from "./sections/content";
import { DynamicSection } from "src/composable/base-dynamic-section";

import {
  PrivacyPolicy,
  PrivacyPoliciesRepository,
} from "src/components/privacy-policy";

interface PrivacyPolicyScreenState extends GenericNullable<PrivacyPolicy> {
  isLoading: boolean;
  selectedVersion: string | null;
}

export default class PrivacyPolicyScreen extends DynamicSection<
  {},
  PrivacyPolicyScreenState
> {
  mainDivRef: RefObject<HTMLDivElement>;
  _privacyPoliciesRepository = new PrivacyPoliciesRepository();

  state: Readonly<PrivacyPolicyScreenState> = {
    content: null,
    version: null,
    availableVersions: null,
    asOfDate: null,
    selectedVersion: null,
    isLoading: true,
  };

  constructor(props: {}) {
    super(props);
    this.mainDivRef = React.createRef();
  }

  componentDidMount = async () => {
    // smooth scroll to top when screen is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
    try {
      this.setState({ isLoading: true });
      await this.getItemsFromServerAndSaveToState();
    } catch (e) {
      ErrorHandler.displayError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  setToAllStates = (privacyPolicy: PrivacyPolicy) => {
    this.setState({
      content: privacyPolicy.content,
      version: privacyPolicy.version,
      availableVersions: privacyPolicy.availableVersions,
      asOfDate: privacyPolicy.asOfDate,
    });
  };

  getItemsFromServerAndSaveToState = async () => {
    try {
      this.setState({ isLoading: true });
      const privacyPolicy = await this._privacyPoliciesRepository.getLatest();
      this.setToAllStates(privacyPolicy);
      this.setState({
        selectedVersion: privacyPolicy.version,
      });
    } catch (e) {
      ErrorHandler.displayError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getItemsWithSpecifiedVersion = async (version: string) => {
    try {
      this.setState({ isLoading: true });
      const privacyPolicy = await this._privacyPoliciesRepository.getByVersion(
        version
      );
      this.setToAllStates(privacyPolicy);
      this.setState({
        selectedVersion: version,
      });
    } catch (e) {
      ErrorHandler.displayError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onSelectedVersionInputChanged = (version: string | null) => {
    this.setState({ selectedVersion: version });
    version && this.getItemsWithSpecifiedVersion(version);
    // scroll to the top of the main div
    if (this.mainDivRef.current) {
      this.mainDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  render(): ReactNode {
    const { content, selectedVersion, availableVersions, asOfDate, isLoading } =
      this.state;

    return (
      <>
        {/* header */}
        <HeaderComponent />

        {/* content */}
        <main
          ref={this.mainDivRef}
          className="flex flex-col py-8 px-6 tablet:px-16 tablet:py-12 desktop:px-24 desktop:py-16 gap-16"
        >
          {isLoading ? (
            <div className="flex flex-col gap-8">
              <Skeleton className="h-20" numberOfSkeletons={15} />
            </div>
          ) : (
            <>
              <PrivacyPolicyScreenHeader asOfDate={asOfDate} />
              <PrivacyPolicyScreenContent content={content} />
              <PrivacyPolicyScreenFooter
                versionSelectorConfig={{
                  selectedValue: selectedVersion,
                  onSelectedInputChange: this.onSelectedVersionInputChanged,
                  values: availableVersions,
                }}
              />
            </>
          )}
        </main>

        {/* footer */}
        <FooterComponent />
      </>
    );
  }
}
