class Translation {
  static screens = {
    home: {
      sections: {
        landingPageOverview: {
          appInsightSection: {
            title:
              "screens.home.sections.landingPageOverview.appInsightSection.title",
            description:
              "screens.home.sections.landingPageOverview.appInsightSection.description",
          },
          cardsSection: {
            monthly: {
              price:
                "screens.home.sections.landingPageOverview.cardsSection.monthly.price",
              subLabel:
                "screens.home.sections.landingPageOverview.cardsSection.monthly.subLabel",
              title:
                "screens.home.sections.landingPageOverview.cardsSection.monthly.title",
              subTitle:
                "screens.home.sections.landingPageOverview.cardsSection.monthly.subTitle",
            },
            yearly: {
              price:
                "screens.home.sections.landingPageOverview.cardsSection.yearly.price",
              subLabel:
                "screens.home.sections.landingPageOverview.cardsSection.yearly.subLabel",
              title:
                "screens.home.sections.landingPageOverview.cardsSection.yearly.title",
              subTitle:
                "screens.home.sections.landingPageOverview.cardsSection.yearly.subTitle",
            },
          },
          appCode: "screens.home.sections.landingPageOverview.appCode",
        },
        musicIntroTextSection: {
          textCarouselSection: {
            title:
              "screens.home.sections.musicIntroTextSection.textCarouselSection.title",
            body: "screens.home.sections.musicIntroTextSection.textCarouselSection.body",
            author:
              "screens.home.sections.musicIntroTextSection.textCarouselSection.author",
          },
        },
        nightyAppIntro: {
          appInsightSection: {
            title:
              "screens.home.sections.nightyAppIntro.appInsightSection.title",
            description:
              "screens.home.sections.nightyAppIntro.appInsightSection.description",
          },
        },
        minddropsIntro: {
          textDisplaySection: {
            title:
              "screens.home.sections.minddropsIntro.textDisplaySection.title",
            subText:
              "screens.home.sections.minddropsIntro.textDisplaySection.subText",
          },
          minddropsCardsSection: {
            title:
              "screens.home.sections.minddropsIntro.minddropsCardsSection.title",
            subTitle:
              "screens.home.sections.minddropsIntro.minddropsCardsSection.subTitle",
          },
        },
        playerIntro: {
          appInsightSection: {
            title: "screens.home.sections.playerIntro.appInsightSection.title",
            description:
              "screens.home.sections.playerIntro.appInsightSection.description",
          },
        },
        textIntro: {
          textDisplaySection: {
            text: "screens.home.sections.textIntro.textDisplaySection.text",
            subText:
              "screens.home.sections.textIntro.textDisplaySection.subText",
          },
        },
        breathIntro: {
          appInsightSection: {
            title: "screens.home.sections.breathIntro.appInsightSection.title",
            description:
              "screens.home.sections.breathIntro.appInsightSection.description",
          },
        },
        testimonials: {
          title: "screens.home.sections.testimonials.title",
        },
        healthIntro: {
          appInsightSection: {
            title: "screens.home.sections.healthIntro.appInsightSection.title",
            description:
              "screens.home.sections.healthIntro.appInsightSection.description",
          },
        },
        fearIntro: {
          appInsightSection: {
            title: "screens.home.sections.fearIntro.appInsightSection.title",
            description:
              "screens.home.sections.fearIntro.appInsightSection.description",
          },
        },
        moodIntro: {
          appInsightSection: {
            title: "screens.home.sections.moodIntro.appInsightSection.title",
            description:
              "screens.home.sections.moodIntro.appInsightSection.description",
          },
        },
        knowHowsIntro: {
          title: "screens.home.sections.knowHowsIntro.title",
          knowHowCardsSection: {
            title:
              "screens.home.sections.knowHowsIntro.knowHowCardsSection.title",
            body: "screens.home.sections.knowHowsIntro.knowHowCardsSection.body",
          },
        },
      },
      redirectButtonText: "screens.home.redirectButtonText",
    },
    privacyPolicy: {
      header: {
        title: "screens.privacyPolicy.header.title",
        date: "screens.privacyPolicy.header.date",
      },
      footer: {
        downloadOptionsLabel:
          "screens.privacyPolicy.footer.downloadOptionsLabel",
        selectVersionLabel: "screens.privacyPolicy.footer.selectVersionLabel",
      },
    },
    termsOfService: {
      header: {
        title: "screens.termsOfService.header.title",
        date: "screens.termsOfService.header.date",
      },
      footer: {
        downloadOptionsLabel:
          "screens.termsOfService.footer.downloadOptionsLabel",
        selectVersionLabel: "screens.termsOfService.footer.selectVersionLabel",
      },
    },
    imprint: {
      header: {
        title: "screens.imprint.header.title",
        date: "screens.imprint.header.date",
      },
      footer: {
        downloadOptionsLabel: "screens.imprint.footer.downloadOptionsLabel",
        selectVersionLabel: "screens.imprint.footer.selectVersionLabel",
      },
    },
    signin: {
      form: {
        title: "screens.signin.form.title",
        subTitle: "screens.signin.form.subTitle",
        emailPlaceholder: "screens.signin.form.emailPlaceholder",
        passwordPlaceholder: "screens.signin.form.passwordPlaceholder",
        or: "screens.signin.form.or",
        forgotPassword: "screens.signin.form.forgotPassword",
        faq: "screens.signin.form.faq",
        faqLinkText: "screens.signin.form.faqLinkText",
        signinButton: "screens.signin.form.signinButton",
      },
      registrationInstruction: {
        title: "screens.signin.registrationInstruction.title",
        downloadText: "screens.signin.registrationInstruction.downloadText",
        createUserText: "screens.signin.registrationInstruction.createUserText",
        signinUserText: "screens.signin.registrationInstruction.signinUserText",
        redeemCodeText: "screens.signin.registrationInstruction.redeemCodeText",
        afterRedeemText:
          "screens.signin.registrationInstruction.afterRedeemText",
        downloadForAndroid:
          "screens.signin.registrationInstruction.downloadForAndroid",
        downloadForIos: "screens.signin.registrationInstruction.downloadForIos",
      },
    },
    profile: {
      redeemCodeButtonText: "screens.profile.redeemCodeButtonText",
    },
    redeemCode: {
      header: "screens.redeemCode.header",
      form: {
        codePlaceholder: "screens.redeemCode.form.codePlaceholder",
        title: "screens.redeemCode.form.title",
        helperText: "screens.redeemCode.form.helperText",
        subHelperText: "screens.redeemCode.form.subHelperText",
        redeemButton: "screens.redeemCode.form.redeemButton",
      },
      success: {
        title: "screens.redeemCode.success.title",
        entitlementTextBefore:
          "screens.redeemCode.success.entitlementTextBefore",
        entitlementTextAfter: "screens.redeemCode.success.entitlementTextAfter",
        redirectHomeButton: "screens.redeemCode.success.redirectHomeButton",
        collectionTextBefore: "screens.redeemCode.success.collectionTextBefore",
        collectionTextAfter: "screens.redeemCode.success.collectionTextAfter",
      },
    },
    howToDeleteMyAccount: {
      title: "screens.howToDeleteMyAccount.title",
      subtitle: "screens.howToDeleteMyAccount.subtitle",
    },
    emailVerification: {
      title: "screens.emailVerification.title",
    },
    overviews: {
      moreInfoText: "screens.overviews.moreInfoText",
    },
    faqs: {
      title: "screens.faqs.title",
    },
    forgotPassword: {
      backToSigninScreenButton:
        "screens.forgotPassword.backToSigninScreenButton",
      form: {
        title: "screens.forgotPassword.form.title",
        subTitle: "screens.forgotPassword.form.subTitle",
        emailPlaceholder: "screens.forgotPassword.form.emailPlaceholder",
        submitButton: "screens.forgotPassword.form.submitButton",
      },
      passwordReseted: {
        title: "screens.forgotPassword.passwordReseted.title",
        subTitle: "screens.forgotPassword.passwordReseted.subTitle",
        submitButton: "screens.forgotPassword.passwordReseted.submitButton",
      },
    },
    shop: {
      shopCard: {
        price: {
          regular: "screens.shop.shopCard.price.regular",
          main: "screens.shop.shopCard.price.main",
        },
        button: {
          addToCart: "screens.shop.shopCard.button.addToCart",
          alreadyAdded: "screens.shop.shopCard.button.alreadyAdded",
        },
      },
      shopCheckoutDisplay: {
        digitalContent: "screens.shop.shopCheckoutDisplay.digitalContent",
        discountValueText: "screens.shop.shopCheckoutDisplay.discountValueText",
        privacyPolicyUncheckedError:
          "screens.shop.shopCheckoutDisplay.privacyPolicyUncheckedError",
        digitalContentUncheckedError:
          "screens.shop.shopCheckoutDisplay.digitalContentUncheckedError",
      },
    },
    paymentResponse: {
      success: {
        text: "screens.paymentResponse.success.text",
      },
      failed: {
        text: "screens.paymentResponse.failed.text",
      },
      backToShopButton: "screens.paymentResponse.backToShopButton",
      openAppButton: "screens.paymentResponse.openAppButton",
    },
  };
  static common = {
    minddrops: "common.minddrops",
    downlaodLinkOptions: {
      googlePlay: {
        subText: "common.downlaodLinkOptions.googlePlay.subText",
        label: "common.downlaodLinkOptions.googlePlay.label",
      },
      appStore: {
        subText: "common.downlaodLinkOptions.appStore.subText",
        label: "common.downlaodLinkOptions.appStore.label",
      },
    },
    header: {
      home: "common.header.home",
      shop: "common.header.shop",
      news: "common.header.news",
      bonus: "common.header.bonus",
      aboutUs: "common.header.aboutUs",
      newsletter: "common.header.newsletter",
      contact: "common.header.contact",
      termsOfService: "common.header.termsOfService",
      privacyPolicy: "common.header.privacyPolicy",
    },
    footer: {
      minddropsSection: {
        title: "common.footer.minddropsSection.title",
        hallo: "common.footer.minddropsSection.hallo",
        imSteiacher: "common.footer.minddropsSection.imSteiacher",
        baden: "common.footer.minddropsSection.baden",
        schweiz: "common.footer.minddropsSection.schweiz",
      },
      smallPrint: {
        title: "common.footer.smallPrint.title",
        daten: "common.footer.smallPrint.daten",
        agb: "common.footer.smallPrint.agb",
        wider: "common.footer.smallPrint.wider",
        impressum: "common.footer.smallPrint.impressum",
      },
      navigation: {
        title: "common.footer.navigation.title",
        home: "common.header.home",
        shop: "common.header.shop",
        news: "common.header.news",
        bonus: "common.header.bonus",
        aboutUs: "common.header.aboutUs",
        newsletter: "common.header.newsletter",
        contact: "common.header.contact",
      },
      followUs: {
        title: "common.footer.followUs.title",
        facebook: "common.footer.followUs.facebook",
        youtube: "common.footer.followUs.youtube",
        instagram: "common.footer.followUs.instagram",
      },
      copyRightText: "common.footer.copyRightText",
      revocationRight: "common.footer.revocationRight",
    },
    providerButtons: {
      google: "common.providerButtons.google",
      apple: "common.providerButtons.apple",
    },
    overviewSection: {
      buttonText: "common.overviewSection.buttonText",
      dataNotFoundText: "common.overviewSection.dataNotFoundText",
    },
    validators: {
      email: "common.validators.email",
    },
    errors: {
      firebase: {
        invalidCredential: "common.errors.firebase.invalidCredential",
        invalidEmail: "common.errors.firebase.invalidEmail",
        userNotFound: "common.errors.firebase.userNotFound",
        networkRequestFailed: "common.errors.firebase.networkRequestFailed",
        unknown: "common.errors.firebase.unknown",
      },
    },
    logout: "common.logout",
  };
  static general = {
    following: "general.following",
    collection: "general.collection",
    collections: "general.collections",
    ok: "general.ok",
    cancel: "general.cancel",
    safeLinkWarning: {
      title: "general.safeLinkWarning.title",
      text: "general.safeLinkWarning.text",
    },
    cookieUsage: {
      message: "general.cookieUsage.message",
    },
    lifetime: "general.lifetime",
    until: "general.until",
    backToSignIn: "general.backToSignIn",
    emailIsNotVerifiedModal: {
      title: "general.emailIsNotVerifiedModal.title",
      dialogText: "general.emailIsNotVerifiedModal.dialogText",
      sendLinkButtonText: "general.emailIsNotVerifiedModal.sendLinkButtonText",
      cancelButtonText: "general.emailIsNotVerifiedModal.cancelButtonText",
    },
    total: "general.total",
    totalAmount: "general.totalAmount",
    continue: "general.continue",
    continueToCheckout: "general.continueToCheckout",
    buy: "general.buy",
    buyNow: "general.buyNow",
    yourCart: "general.yourCart",
    article: "general.article",
    backToTop: "general.backToTop",
  };
  static markdowns = {
    shop: {
      privacyPolicy: "markdowns.shop.privacyPolicy",
      screens: {
        privacyPolicy: "markdowns.shop.screens.privacyPolicy",
        revocationRight: "markdowns.shop.screens.revocationRight",
      },
    },
  };
}

export default Translation;
