import Translation from "src/translations/locales/translation";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FooterComponent } from "src/shared-components/footer";
import { ShopPrivacyPolicyScreenContent } from "./sections/content";

export default function ShopPrivacyPolicyScreen() {
  const { t } = useTranslation();

  // smooth scroll to top when screen is loaded
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <main className="flex flex-col py-8 pb-0 px-6 tablet:px-16 tablet:py-12 desktop:px-24 desktop:py-16 gap-16">
        {/* content */}
        <ShopPrivacyPolicyScreenContent
          content={t(Translation.markdowns.shop.screens.privacyPolicy)}
        />
      </main>
      {/* footer */}
      <FooterComponent isFooterForShopScreen={true} />
    </>
  );
}
